import h from 'hyperscript';

import 'ui/truncated-p/truncated-p';
// The following import is used only for type checking and then removed.

import type { TruncatedP } from 'ui/truncated-p/truncated-p';

import { escapeAndMarkHTML } from 'cadenza/utils/escape-and-mark';

import './alert.css';

// we need the array of strings for internal implementation
const ALERT_TYPES = [ 'error', 'info', 'success', 'warning' ] as const;

// derive the type from the array of strings
export type AlertType = typeof ALERT_TYPES[number];

export class Alert extends HTMLElement {

  _titleElement;
  _messageElement;

  constructor () {
    super();
    // initially hidden to allow for messages without a title
    this._titleElement = h('p.title', { hidden: true }) as HTMLElement;
    this._messageElement = h('d-truncated-p.message', { hidden: true }) as TruncatedP;
  }

  connectedCallback () {
    this.setAttribute('role', 'alert');
    this.classList.add('d-alert');

    const title = this.getAttribute('title');
    if (title) {
      this.title = title;
      this._titleElement.hidden = false;
    }

    const typeAttr = this.getAttribute('type');
    if (typeAttr) {
      this.type = typeAttr as AlertType; // type assertion is fine, because the setter is safe
    }

    const message = this.getAttribute('message');
    if (message) {
      this.message = message;
    }

    const truncatedMessage = this.getAttribute('truncated-message');
    if (truncatedMessage) {
      this.truncatedMessage = truncatedMessage;
    }

    // Firefox + screen reader won't work here: https://github.com/nvaccess/nvda/issues/8873
    this.append(this._titleElement, this._messageElement);
  }

  set type (value: AlertType) {
    ALERT_TYPES.forEach(type => this.classList.toggle(`d-alert-${type}`, (value === type)));
  }

  get type (): AlertType {
    return ALERT_TYPES.find(type => this.classList.contains(`d-alert-${type}`)) || 'error';
  }

  set title (text: string) {
    if (text) {
      this._titleElement.innerHTML = escapeAndMarkHTML(text);
      this._titleElement.hidden = false;
    } else {
      this._titleElement.innerHTML = '';
      this._titleElement.hidden = true;
    }
  }

  get title (): string {
    return this._titleElement.innerHTML;
  }

  set message (text: string) {
    this._messageElement.fullText = text;
    this._messageElement.hidden = !text;
  }

  get message (): string {
    return this._messageElement.fullText;
  }

  set truncatedMessage (value: string | undefined) {
    this._messageElement.truncatedText = value;
  }

  get truncatedMessage (): string | undefined {
    return this._messageElement.truncatedText;
  }

}

customElements.define('d-alert', Alert);
