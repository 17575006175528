/*
 * The execution of this "base" bundle is deferred, so that it's not render-blocking.
 * That means that we cannot rely on a certain execution order relative to other bundles and scripts.
 *
 * In general, we want to know the dependencies of our pages, so their dependencies should be defined
 * explicitly in the respective page's bundle.
 */

// Note: CSS is split out of the JS bundles and won't be deferred.
// eslint-disable-next-line no-restricted-imports -- We do not have a Webpack resolve alias for that.
import '../css/base.css';

import 'ui/alert/alert';
import 'ui/alerts/alerts';
import 'ui/progress-spinner/progress-spinner';
import 'ui/sidebar/sidebar';
import 'ui/progress-spinner-overlay/progress-spinner-overlay';

import { setupEllipsis } from 'cadenza/utils/ellipsis';
import 'cadenza/header/subnav';
import 'cadenza/header/topnav';
import { logger } from 'cadenza/utils/logging';
import { setupKeynav } from 'cadenza/utils/setup-keynav';
import { setupSessionPoller } from 'cadenza/session-polling/session-poller';

setupSessionPoller(window.Disy.cadenzaSessionPollingInterval);
setupKeynav();
setupEllipsis();

if (window.Disy.debug) {
  document.addEventListener('focusout', (event) => {
    // "focusout" is dispatched before the element is removed.
    // So we need to defer the contains() check.
    requestAnimationFrame(() => {
      if (document.activeElement === document.body && !document.body.contains(event.target)) {
        logger.log('Please fix the focus management of your component: '
          + 'The focus was dropped, because the active element (see below) was removed from the DOM. '
          + 'As a result, the document.body is focused now, which breaks the UX for keyboard users',
        event.target);
      }
    });
  });
}
