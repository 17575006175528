import './alerts.css';
import h from 'hyperscript';

const ATTR_COMPACT_STYLE = 'compact-message-style';
const NAME = 'd-alerts';

/**
 * Component that allows programmatic control of a group of d-alert components.
 * Its API accepts alert-like objects that should have the following fields, matching the properties of d-alert:
 * type - a String, see the accepted types for d-alert
 * title - String, the title of the alert
 * message - String, the text message of the alert
 *
 * The component can contain d-alert elements. This is useful for server-side rendering.
 * Then JS can take over and replace these pre-rendered alerts or add new ones.
 */
export class Alerts extends HTMLElement {

  connectedCallback () {
    this.classList.add(NAME);
  }

  /**
   * The currently displayed alerts
   *
   * @return Array of alert objects
   */
  get alerts () {
    return [ ...this.children ].map(({ type, title, message }) => ({ type, title, message }));
  }

  /**
   * Sets the displayed alerts, replacing the current ones.
   *
   * @param alerts - An array of alert objects
   */
  set alerts (alerts) {
    this.innerHTML = '';
    this.append(...alerts.map(alert => this._buildAlertElement(alert)));
  }

  /**
   * If set to true, the alerts use the special compact styling.
   *
   * @param value - boolean value
   */
  set compactMessages (value) {
    this.toggleAttribute(ATTR_COMPACT_STYLE, value);
  }

  get compactMessages () {
    return this.hasAttribute(ATTR_COMPACT_STYLE);
  }

  _updateAlertElements () {
    this.innerHTML = '';
    this._alerts.forEach(msg => this.appendChild(this._buildAlertElement(msg)));
  }

  _buildAlertElement (message) {
    const compactCssClass = this.compactMessages ? '.d-alert-small' : '';
    return h(`d-alert.d-alert-${message.type}${compactCssClass}`, {
      title: message.title,
      message: message.message
    });
  }

}

customElements.define(NAME, Alerts);
